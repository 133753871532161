<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="none" name="plantCd" :disabled="plantDisabled" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-process
            label="공정"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
      </template>
    </c-search-box>
      <!-- checkDisableColumn="riskRegisterFlag" -->
    <c-table
      ref="table"
      title="3단계위험성평가 목록"
      :columns="gridRisk.columns"
      :data="gridRisk.data" 
      :merge="gridRisk.merge"
      gridHeightAuto
      :selection="popupParam.type"
      rowKey="ramThirdAssessScenarioId"
      @linkClick="linkClick"
      @rowDblclick="rowDblclick"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='ramRiskLevelName'">
          <q-chip text-color="white" :style="`background-color:${props.row.riskColor} !important;color:white;`" outline square>
            {{props.row[col.name]}}
          </q-chip>
        </template>
        <template v-else-if="col.name==='ramAfterRiskLevelName'">
          <q-chip text-color="white" :style="`background-color:${props.row.riskColorAfter} !important;color:white;`" outline square>
            {{props.row[col.name]}}
          </q-chip>
        </template>
      </template>
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <c-btn label="선택" icon="check" color="orange" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'assess-risk-register-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          processCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        ramTechniqueCd: 'RT00000035',
        processCd: null,
        ibmStepCd: null,
        mdmSopId: null,
      },
      gridRisk: {
        merge: [
          { index: 0, colName: 'assessmentName' },
          { index: 1, colName: 'processGubun' },
        ],
        height: '',
        data: [],
        columns: [
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'left',
            style: 'width:15%',
            sortable: false,
          },
          {
            name: 'riskHazardQuestionName',
            field: 'riskHazardQuestionName',
            label: '유해위험요인',
            align: 'left',
            style: 'width:40%',
            sortable: false,
          },
          // {
          //   name: 'currentSafetyMeasures',
          //   field: 'currentSafetyMeasures',
          //   label: '현재안전조치',
          //   align: 'left',
          //   style: 'width:180px',
          //   sortable: false,
          // },
          // {
          //   name: 'ramRiskLevelName',
          //   field: 'ramRiskLevelName',
          //   // 판단결과
          //   label: '개선 전<br/>판단결과',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          //   type: 'custom'
          // },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 개선대책
            label: '개선대책',
            align: 'left',
            style: 'width:20%',
            sortable: false,
          },
          {
            name: 'ramAfterRiskLevelName',
            field: 'ramAfterRiskLevelName',
            // 판단결과
            label: '개선 후<br/>판단결과',
            align: 'center',
            style: 'width:10%',
            sortable: false,
            type: 'custom'
          },
        ],
      },
      colorItems: [],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      imprData: null,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    plantDisabled() {return Boolean(this.popupParam.plantCd)},
    processDisabled() {return Boolean(this.popupParam.processCd)},
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.gridRisk.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.third.scenario.list.url
      if (this.popupParam.plantCd) {this.searchParam.plantCd = this.$_.clone(this.popupParam.plantCd)}
      if (this.popupParam.processCd) {this.searchParam.processCd = this.$_.clone(this.popupParam.processCd)}
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridRisk.data = _result.data;
      },);
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 위험성평가가 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {this.$emit('closePopup', selectData)}
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
  }
};
</script>